// extracted by mini-css-extract-plugin
export var SliderWidth = "Solutions-module--SliderWidth--ca79e";
export var arrowImg = "Solutions-module--arrowImg--db433";
export var bottomContent = "Solutions-module--bottomContent--04630";
export var bottomHead = "Solutions-module--bottomHead--d7438";
export var bottomPort = "Solutions-module--bottomPort--9ac07";
export var bottomPort2 = "Solutions-module--bottomPort2--5f342";
export var bottomTags = "Solutions-module--bottomTags--ce8e4";
export var bottomhead1 = "Solutions-module--bottomhead1--fe600";
export var bottomtag1 = "Solutions-module--bottomtag1--29da0";
export var bottomtag2 = "Solutions-module--bottomtag2--f9f74";
export var bottomtag3 = "Solutions-module--bottomtag3--dc42a";
export var bottomtag3Hover = "Solutions-module--bottomtag3Hover--da09f";
export var cardWrapper = "Solutions-module--cardWrapper--09e0b";
export var cards = "Solutions-module--cards--8ac4c";
export var description = "Solutions-module--description--1c256";
export var heading = "Solutions-module--heading--d27d0";
export var headingPremium = "Solutions-module--headingPremium--d511b";
export var mainWorkProjectHireCTO = "Solutions-module--mainWorkProjectHireCTO--63f5c";
export var mainWorkProjectsWebServices = "Solutions-module--mainWorkProjectsWebServices--27bf1";
export var portfolioArrowIcon = "Solutions-module--portfolioArrowIcon--4e083";
export var portfolioArrowIconCover = "Solutions-module--portfolioArrowIconCover--5a517";
export var portfolioArrowRightIconCover = "Solutions-module--portfolioArrowRightIconCover--3ae6f";
export var portfolioLinkImage = "Solutions-module--portfolioLinkImage--445ec";
export var premiumImg = "Solutions-module--premiumImg--5e91d";
export var project2 = "Solutions-module--project2--1ac7f";
export var projectsAll = "Solutions-module--projectsAll--6a021";
export var providingImg = "Solutions-module--providingImg--4897f";
export var reactJsIconContainer = "Solutions-module--reactJsIconContainer--4b343";
export var reactJsIconContainerLeft = "Solutions-module--reactJsIconContainerLeft--96707";
export var teamButton = "Solutions-module--teamButton--e721b";
export var trailBg = "Solutions-module--trailBg--2f1e0";