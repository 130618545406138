import { Link } from "gatsby"
import React from "react"
import * as styles from "./QuickReads.module.scss"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
const QuickReads = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.quickReads}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <Row className={`${styles.cardWrapper} gap-30`}>
            {strapiData?.cards?.map((e, i) => (
              <Col xl={4} md={6} xs={12} key={i} className="gap-30 d-flex">
                <div className={styles.card}>
                  <Link
                    to={
                      i === 0
                        ? "/blog/django-vs-angular-which-one-to-choose-in-2022/"
                        : i === 1
                        ? "/blog/angularjs-vs-reactjs/"
                        : i === 2
                        ? "/blog/how-can-angularjs-development-benefit-your-business/"
                        : ""
                    }
                  >
                    <img
                      className={styles.blogimg}
                      decoding="async"
                      loading="lazy"
                      src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                      alt={e?.title}
                    />
                  </Link>
                  <div
                    className={`${`d-flex justify-content-between`} ${
                      styles.topDetails
                    }`}
                  >
                    <Link
                      to={
                        i === 0
                          ? "/blog/category/trends/"
                          : i === 1
                          ? "/blog/category/iot/"
                          : i === 2
                          ? "/blog/category/web/"
                          : ""
                      }
                    >
                      {" "}
                      <p className={styles.category}>
                        {i === 0
                          ? "Trends"
                          : i === 1
                          ? "IOT"
                          : i === 2
                          ? "Web"
                          : ""}
                      </p>
                    </Link>
                    <div
                      className={`d-flex align-items-center ${styles.writer}`}
                    >
                      <div>
                        <Link
                          to={
                            i === 0
                              ? "/author/sadia-aziz/"
                              : i === 1
                              ? "/author/furqan-aziz/"
                              : i === 2
                              ? "/author/shahid-aziz/"
                              : ""
                          }
                        >
                          <p
                            className={styles.writerName}
                            dangerouslySetInnerHTML={{
                              __html: e?.subTitle,
                            }}
                          />
                        </Link>
                      </div>
                      <div>
                        <p className={styles.minRead}>
                          {i === 0
                            ? "3 min read"
                            : i === 1
                            ? "3 min read"
                            : i === 2
                            ? "2 min read"
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Link
                    to={
                      i === 0
                        ? "/blog/django-vs-angular-which-one-to-choose-in-2022/"
                        : i === 1
                        ? "/blog/angularjs-vs-reactjs/"
                        : i === 2
                        ? "/blog/how-can-angularjs-development-benefit-your-business/"
                        : ""
                    }
                  >
                    <h3>{e?.title}</h3>
                  </Link>
                  <p className={styles.blogDesc}>
                    {i === 0
                      ? "In this blog read about Django vs Angular from scratch. See which framework you should choose to better fulfill your web development needs in 2023."
                      : i === 1
                      ? "In this article, we'll highlight key differences between AngularJS and ReactJS. We'll also discuss why you should prefer one over the other.Let's dive into it"
                      : i === 2
                      ? "Today we will learn about some key features of AngularJS. We will also try and understand how AngularJS development can benefit your business.So let's dive into it."
                      : ""}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QuickReads
