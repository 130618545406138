// extracted by mini-css-extract-plugin
export var bannerBtnHowAngular = "HowHireAngular-module--bannerBtnHowAngular--f4e28";
export var card = "HowHireAngular-module--card--99b9e";
export var cardAllData = "HowHireAngular-module--cardAllData--e009b";
export var cardContent = "HowHireAngular-module--cardContent--47777";
export var cardWrapper = "HowHireAngular-module--cardWrapper--3373e";
export var description = "HowHireAngular-module--description--22aed";
export var getBtnAngular = "HowHireAngular-module--getBtnAngular--11ab8";
export var headerContent = "HowHireAngular-module--headerContent--4d587";
export var heading = "HowHireAngular-module--heading--5a96e";
export var howHireAngular = "HowHireAngular-module--howHireAngular--51d77";
export var lottieImage = "HowHireAngular-module--lottieImage--4f418";
export var steps = "HowHireAngular-module--steps--db7e6";