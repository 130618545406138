import React from "react"
import * as styles from "./Statics.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const Statics = () => {
  const data = [
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/g2_logo_2_978394235e.png",
      number: "5",
      star: "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_2f18a9b020.png",
      desc: "Trustpilot",
    },
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000006482_1140d6cc9d.png",
      number: "5",
      star: "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_2f18a9b020.png",
      desc: "Clutch",
    },
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000006483_602836f873.png",
      number: "5",
      star: "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_2f18a9b020.png",
      desc: "Goodfirms",
    },
  ]
  return (
    <div className={styles.staticsTypeScript}>
      <Container className={styles.staticsContainer}>
        <Row className={styles.Wrapper}>
          {data &&
            data?.map((e, i) => (
              <Col
                lg={4}
                md={12}
                sm={12}
                xs={12}
                key={i}
                className={
                  i === 0 ? styles.card1 : i === 1 ? styles.card2 : styles.card
                }
              >
                <Row>
                  <Col lg={3} md={3} xs={3} sm={3}>
                    <div className={styles.cardImg}>
                      <img
                        src={e?.img}
                        alt={e?.desc}
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                  </Col>
                  <Col lg={9} md={9} sm={9} xs={9} className={styles.starDiv}>
                    <div className="d-flex align-items-center">
                      <div>{e?.number}</div>
                      <div className={styles.starImage}>
                        <img
                          src={e?.star}
                          alt="stars"
                          style={{ height: "20px", width: "124px" }}
                        />
                      </div>
                    </div>
                    <div>
                      <p>{e?.desc}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  )
}

export default Statics
