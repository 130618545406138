// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "AngularTechStacks-module--TechStacksWebHireAngular--f3f74";
export var cardBg = "AngularTechStacks-module--cardBg--c353e";
export var cards = "AngularTechStacks-module--cards--6433b";
export var cardsContentAngular = "AngularTechStacks-module--cardsContentAngular--1eb73";
export var description = "AngularTechStacks-module--description--64312";
export var heading = "AngularTechStacks-module--heading--2c7b0";
export var iconssCard = "AngularTechStacks-module--iconssCard--fefcd";
export var nav = "AngularTechStacks-module--nav--ab464";
export var navItem = "AngularTechStacks-module--nav-item--daef8";
export var navbarBlock = "AngularTechStacks-module--navbarBlock--079a3";
export var tabData = "AngularTechStacks-module--tabData--b34c6";
export var tech = "AngularTechStacks-module--tech--0a77f";
export var techIcon = "AngularTechStacks-module--techIcon--27799";
export var techImg = "AngularTechStacks-module--techImg--d0ca6";
export var technologyIcon = "AngularTechStacks-module--technologyIcon--ef8c9";