// extracted by mini-css-extract-plugin
export var attachment = "hireDeveloperForm-module--attachment--f92a5";
export var attachmentLabel = "hireDeveloperForm-module--attachmentLabel--f7db3";
export var errorTxt = "hireDeveloperForm-module--errorTxt--4235b";
export var fieldColor = "hireDeveloperForm-module--fieldColor--51a55";
export var formControl = "hireDeveloperForm-module--form-control--89763";
export var formInner = "hireDeveloperForm-module--formInner--52444";
export var hr = "hireDeveloperForm-module--hr--bf481";
export var newlinks = "hireDeveloperForm-module--newlinks--66d1e";
export var productHead = "hireDeveloperForm-module--productHead--1550e";
export var query = "hireDeveloperForm-module--query--dfe07";
export var salesFormCheckBoxSize = "hireDeveloperForm-module--salesFormCheckBoxSize--c0e6f";
export var salesFormRowHeight = "hireDeveloperForm-module--salesFormRowHeight--fc9e6";