// extracted by mini-css-extract-plugin
export var blogDesc = "QuickReads-module--blogDesc--9d5fd";
export var blogimg = "QuickReads-module--blogimg--7e259";
export var card = "QuickReads-module--card--b36cd";
export var cardWrapper = "QuickReads-module--cardWrapper--5841a";
export var category = "QuickReads-module--category--f64d5";
export var heading = "QuickReads-module--heading--b5cdf";
export var minRead = "QuickReads-module--minRead--59b7e";
export var quickReads = "QuickReads-module--quickReads--1c2ed";
export var topDetails = "QuickReads-module--topDetails--695a6";
export var writer = "QuickReads-module--writer--373da";
export var writerName = "QuickReads-module--writerName--a1d30";