// extracted by mini-css-extract-plugin
export var AngularDevelopersWeb = "HireDevelopers-module--AngularDevelopersWeb--60013";
export var allSkills = "HireDevelopers-module--allSkills--78d6f";
export var bottomRating = "HireDevelopers-module--bottomRating--b5fcd";
export var btnShowMore = "HireDevelopers-module--btnShowMore--2fd78";
export var card = "HireDevelopers-module--card--21364";
export var cardDesc = "HireDevelopers-module--cardDesc--c09b4";
export var cardWrapper = "HireDevelopers-module--cardWrapper--cc778";
export var description = "HireDevelopers-module--description--7f842";
export var devContent = "HireDevelopers-module--devContent--b1af9";
export var developersData = "HireDevelopers-module--developersData--3bc5d";
export var experience = "HireDevelopers-module--experience--9efff";
export var expert = "HireDevelopers-module--expert--8e2f4";
export var heading = "HireDevelopers-module--heading--7ec21";
export var mernIcon = "HireDevelopers-module--mernIcon--88c85";
export var review = "HireDevelopers-module--review--02595";
export var skill = "HireDevelopers-module--skill--a24f8";
export var skills = "HireDevelopers-module--skills--87fbb";
export var typeBtn = "HireDevelopers-module--typeBtn--8cb82";
export var typedevBtn = "HireDevelopers-module--typedevBtn--eb322";
export var whitePlus = "HireDevelopers-module--whitePlus--a2aa9";