import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./AngularTechStacks.scss"
import * as styles from "./AngularTechStacks.module.scss"

const data = [
  {
    title: "Languages",
    tech: [
      {
        name: "HTML5",
        icon: "https://invozone-backend.s3.amazonaws.com/html_732cfbbb9e.svg",
      },
      {
        name: "CSS3",
        icon: "https://invozone-backend.s3.amazonaws.com/css3_a21e492b80.svg",
      },
      {
        name: "JavaScript",
        icon: "https://invozone-backend.s3.amazonaws.com/javascript_1d5a458fb2.svg",
      },
      {
        name: "TypeScript",
        icon: "https://invozone-backend.s3.amazonaws.com/typescript_7870edf20c.svg",
      },
      {
        name: "GraphQL",
        icon: "https://invozone-backend.s3.amazonaws.com/graphql_64900f0cb9.svg",
      },
    ],
  },
  {
    title: "Libraries & UI Libraries",
    tech: [
      {
        name: "Bootstrap",
        icon: "https://invozone-backend.s3.amazonaws.com/bootstrap_0d3e2ef270.svg",
      },
      {
        name: "RxJs",
        icon: "https://invozone-backend.s3.amazonaws.com/rx_js_c25c3dc4c7.svg",
      },
      {
        name: "NgRx",
        icon: "https://invozone-backend.s3.amazonaws.com/ngrx_1_914e434aee.svg",
      },
      {
        name: "React",
        icon: "https://invozone-backend.s3.amazonaws.com/react_js_6c607ab45c.svg",
      },
      {
        name: "jQuery",
        icon: "https://invozone-backend.s3.amazonaws.com/jquery_ec723c935f.svg",
      },
      {
        name: "Angular CLI",
        icon: "https://invozone-backend.s3.amazonaws.com/angular_Cli_103dc19874.svg",
      },
      {
        name: "Ng-Bootstrap",
        icon: "https://invozone-backend.s3.amazonaws.com/ng_Bootstrap_6da435b781.svg",
      },
      {
        name: "Angular Material",
        icon: "https://invozone-backend.s3.amazonaws.com/angular_Material_f46f83a247.svg",
      },
      {
        name: "Ngx-translate",
        icon: "https://invozone-backend.s3.amazonaws.com/ngx_translate_272d048839.svg",
      },
      {
        name: "Clarity Design System",
        icon: "https://invozone-backend.s3.amazonaws.com/clarity_a2658e6cd4.svg",
      },
      {
        name: "Nebular",
        icon: "https://invozone-backend.s3.amazonaws.com/nebuler_b6b7f7d8f5.svg",
      },
      {
        name: "Ng-Zorro",
        icon: "https://invozone-backend.s3.amazonaws.com/ng_Z_Orro_0c1cbc2685.svg",
      },
      {
        name: "PrimeNG",
        icon: "https://invozone-backend.s3.amazonaws.com/prime_bb855a5ece.svg",
      },
      {
        name: "Teradata Covalent",
        icon: "https://invozone-backend.s3.amazonaws.com/tredda_22eb996efb.svg",
      },
      {
        name: "Angular Flex Layout",
        icon: "https://invozone-backend.s3.amazonaws.com/flex_b3b175ea3f.svg",
      },
    ],
  },
  {
    title: "Frameworks",
    tech: [
      {
        name: "Ionic",
        icon: "https://invozone-backend.s3.amazonaws.com/ionic_81fe921ff9.svg",
      },
      {
        name: "Bootstrap",
        icon: "https://invozone-backend.s3.amazonaws.com/bootstrap_0d3e2ef270.svg",
      },
      {
        name: "NestJs",
        icon: "https://invozone-backend.s3.amazonaws.com/nest_js_9a21773270.svg",
      },
      {
        name: "Scully",
        icon: "https://invozone-backend.s3.amazonaws.com/Scully_0a31198239.svg",
      },
      {
        name: "Universal",
        icon: "https://invozone-backend.s3.amazonaws.com/universal_ed3ee57c2e.svg",
      },
      {
        name: "AngularFire",
        icon: "https://invozone-backend.s3.amazonaws.com/angular_Fire_6a90c12f46.svg",
      },
      {
        name: "Capacitor",
        icon: "https://invozone-backend.s3.amazonaws.com/Capacitor_05bd05b6cc.svg",
      },
      {
        name: "Flutter",
        icon: "https://invozone-backend.s3.amazonaws.com/flutter_c088c7bb43.svg",
      },
      {
        name: "Vue.js",
        icon: "https://invozone-backend.s3.amazonaws.com/vue_js_363d529824.svg",
      },
      {
        name: "Tailwind",
        icon: "https://invozone-backend.s3.amazonaws.com/tailwind_6e2075c057.svg",
      },
      {
        name: "AngularJS",
        icon: "https://invozone-backend.s3.amazonaws.com/angular_d7f64e01b4.svg",
      },
      {
        name: "Angular2+",
        icon: "https://invozone-backend.s3.amazonaws.com/angular_d7f64e01b4.svg",
      },
    ],
  },
  {
    title: "Testing",
    tech: [
      {
        name: "Postman",
        icon: "https://invozone-backend.s3.amazonaws.com/postman_531dd20323.svg",
      },
      {
        name: "Jmeter",
        icon: "https://invozone-backend.s3.amazonaws.com/jmeter_2b862ac481.svg",
      },
      {
        name: "Selenium",
        icon: "https://invozone-backend.s3.amazonaws.com/selenium_ecde5053d2.svg",
      },
      {
        name: "Cypress",
        icon: "https://invozone-backend.s3.amazonaws.com/cypress_216f4b3135.svg",
      },
      {
        name: "BrowserStack",
        icon: "https://invozone-backend.s3.amazonaws.com/browserstack_858d0150a1.svg",
      },
      {
        name: "Bugzilla",
        icon: "https://invozone-backend.s3.amazonaws.com/bugzilla_2760fd8704.svg",
      },
      {
        name: "SoapUI",
        icon: "https://invozone-backend.s3.amazonaws.com/soapui_fde55b50e3.svg",
      },
      {
        name: "Jasmine",
        icon: "https://invozone-backend.s3.amazonaws.com/jasmine_b9be38fa6b.svg",
      },
      {
        name: "Karma",
        icon: "https://invozone-backend.s3.amazonaws.com/karma_c5e86ed042.svg",
      },
      {
        name: "Testbed",
        icon: "https://invozone-backend.s3.amazonaws.com/Testbed_c0ad45314b.svg",
      },
      {
        name: "Protractor",
        icon: "https://invozone-backend.s3.amazonaws.com/Protractor_e15234e5d4.svg",
      },
    ],
  },
  {
    title: "Platforms",
    tech: [
      {
        name: "Firebase",
        icon: "https://invozone-backend.s3.amazonaws.com/firebase_6cd830fadd.svg",
      },
      {
        name: "Docker",
        icon: "https://invozone-backend.s3.amazonaws.com/docker_aef732bbc3.svg",
      },
      {
        name: "DaprHeroku",
        icon: "https://invozone-backend.s3.amazonaws.com/heroku_c83bd3b09d.svg",
      },
      {
        name: "Azure AWS",
        icon: "https://invozone-backend.s3.amazonaws.com/azure_0b43c63ff1.svg",
      },
      {
        name: "Lambda",
        icon: "https://invozone-backend.s3.amazonaws.com/lambdanew_2570473791.svg",
      },
      {
        name: "AWS",
        icon: "https://invozone-backend.s3.amazonaws.com/tech5_29272db405.svg",
      },
    ],
  },
]

const AngularTechStacks = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="WebTabStacksAngularHire">
      <div className={styles.TechStacksWebHireAngular}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContentAngular}>
              <div>
                <Nav variant="pills" className={`WebTabStacksAngularHire`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockAngularWeb">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default AngularTechStacks
