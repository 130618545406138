// extracted by mini-css-extract-plugin
export var Golang = "AngularCombination-module--Golang--b8dbe";
export var angularCombination = "AngularCombination-module--angularCombination--a0c68";
export var bannerBtnGolangBook = "AngularCombination-module--bannerBtnGolangBook--43bf3";
export var btn_white2_border_book_Goolang_dev = "AngularCombination-module--btn_white2_border_book_Goolang_dev--9e52b";
export var card = "AngularCombination-module--card--23e45";
export var cardWrapper = "AngularCombination-module--cardWrapper--6ba0e";
export var description = "AngularCombination-module--description--51a2f";
export var heading = "AngularCombination-module--heading--a4b45";
export var imgs = "AngularCombination-module--imgs--6440b";
export var plus1 = "AngularCombination-module--plus1--b9a12";
export var plus2 = "AngularCombination-module--plus2--b0b3e";
export var plus3 = "AngularCombination-module--plus3--d8e88";
export var plus4 = "AngularCombination-module--plus4--b040c";
export var typeScriptImg = "AngularCombination-module--typeScriptImg--401a4";