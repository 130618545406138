// extracted by mini-css-extract-plugin
export var bannerHeading = "Banner-module--bannerHeading--baa29";
export var bannerSubtitle = "Banner-module--bannerSubtitle--b92d3";
export var bannerSubtitle2 = "Banner-module--bannerSubtitle2--ec5a1";
export var cards = "Banner-module--cards--4025c";
export var form = "Banner-module--form--43f18";
export var heading = "Banner-module--heading--06c2d";
export var hireAngularTopBanner = "Banner-module--hireAngularTopBanner--5fa59";
export var talent = "Banner-module--talent--e3845";
export var text = "Banner-module--text--4d8da";
export var tick = "Banner-module--tick--85dd0";