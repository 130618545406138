import { Link } from "gatsby"
import React from "react"
import * as styles from "./AngularCombination.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import angular from "../../images/hire-typescript/angular.svg"
import node from "../../images/angular/node.svg"
import python from "../../images/angular/pythonn.svg"
import ruby from "../../images/angular/ruby 1.svg"
import php from "../../images/angular/php.svg"
import plus from "../../images/hire-typescript/plusType.svg"
const AngularCombinitation = ({ strapiData }) => {
  const nextGenData = [
    {
      stcak1: angular,
      stack2: node,
      title: "Angular + Node.js (JavaScript/TypeScript)",
      desc: "Angular at the front end and Node.js (JavaScript/TypeScript) at the back end are robust choices for full-stack JavaScript applications.",
    },
    {
      stcak1: angular,
      stack2: python,
      title: "Angular + Django (Python)",
      desc: "Angular at the front end & our Django services on the backend can build modern web apps with real-time collaboration & rapid prototyping.",
    },
    {
      stcak1: angular,
      stack2: ruby,
      title: "Angular + Ruby on Rails (RoR)",
      desc: "The apps that are API-driven or need rich interfaces are perfect to be built on this combo-Angular (TypeScript) on the front end & RoR on the back end.",
    },
    {
      stcak1: angular,
      stack2: php,
      title: "Angular + PHP",
      desc: "Angular (front-end) and PHP (server-side scripting language) is used in the development of dynamic and interactive web apps.",
    },
  ]
  return (
    <div className={styles.angularCombination}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 justify-content-center ${styles.cardWrapper}`}>
          {nextGenData.map((e, i) => (
            <Col xl={6} md={6} xs={12} key={i} className={styles.Golang}>
              <div className={styles.card}>
                <div className={styles.imgs}>
                  <img
                    className={styles.typeScriptImg}
                    src={e?.stcak1}
                    decoding="async"
                    loading="lazy"
                    alt={e?.title}
                  />
                  <img
                    className={`${styles[`plus${i + 1}`]}`}
                    src={plus}
                    alt="Icon"
                  />
                  <img
                    className={styles.typeScriptImg}
                    src={e?.stack2}
                    decoding="async"
                    loading="lazy"
                    alt={e?.title}
                  />
                </div>
                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.desc,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
        <div className={`${styles.bannerBtnGolangBook}`}>
          {strapiData?.buttons[0] && (
            <Link
              to="#typescriptform"
              className={styles.btn_white2_border_book_Goolang_dev}
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

export default AngularCombinitation
