// extracted by mini-css-extract-plugin
export var AngularIndusTechHireWeb = "AngularTechStacksMobile-module--AngularIndusTechHireWeb--2011d";
export var cardBg = "AngularTechStacksMobile-module--cardBg--86c6e";
export var cards = "AngularTechStacksMobile-module--cards--dbcf0";
export var cardsContent = "AngularTechStacksMobile-module--cardsContent--a6005";
export var description = "AngularTechStacksMobile-module--description--f9224";
export var heading = "AngularTechStacksMobile-module--heading--b860e";
export var iconssCard = "AngularTechStacksMobile-module--iconssCard--f650a";
export var nav = "AngularTechStacksMobile-module--nav--5f525";
export var navItem = "AngularTechStacksMobile-module--nav-item--59234";
export var navbarBlock = "AngularTechStacksMobile-module--navbarBlock--68baa";
export var tabData = "AngularTechStacksMobile-module--tabData--ef2ae";
export var tech = "AngularTechStacksMobile-module--tech--f6d73";
export var techIcon = "AngularTechStacksMobile-module--techIcon--e6569";
export var techImg = "AngularTechStacksMobile-module--techImg--e56a3";
export var technologyIcon = "AngularTechStacksMobile-module--technologyIcon--de914";